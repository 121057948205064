/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class Probate extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("probate");
         window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.toggle("probate");
    }

    render() {
        
        return (
            <>
                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">
                                <h5 className="text-on-back">Probate, Intestacy, Deputyship & Court of Protection </h5>

                                <p>We are here every step of the way to help you through this difficult time. Our team offers
                                    a variety of services depending on our clients or their loved ones requirements and
                                    wishes. This could be legal advice dealing with Inheritance Tax, obtaining a grant of probate,
                                    securing letters of administration or even dealing with the whole estate on your behalf.
                                    It may be we can provide practical support in this stressful time providing as much or
                                    as little help as you need.  We can tailor our services to meet your needs.</p>


                                   <p> We pride ourselves on our caring, friendly and professional approach, making sure that everything is dealt with thoroughly, efficiently and sensitively.

                                       Our goal is to make matters as simple and effortless as possible for you and your
                                   loved ones.</p>

                                <p> We specialise in the following areas: </p>
                                <ul>
                                    <li>Administration of Estate</li>
                                    <li>Court of Protection</li>
                                    <li>Deputyship inclusive of Management of Deputyship</li>
                                    <li>Intestacy</li>
                                    <li>Lasting Power of Attorney Affairs</li>
                                    <li>Letters of Administration</li>
                                    <li>Probate</li>
                                </ul>

                            <br/>

                                <p><b>Probate</b></p>
                                    <p>Upon death, the estate assets are frozen and require the aid of Probate to unfreeze
                                        and redistribute the assets (money, property and possessions) to then administer
                                        a Will when someone has passed away, if there is a Will. This
                                        is usually the responsibility of the Executors to then apply for
                                        Probate which is normally legally required to ensure the estate is dealt with
                                        correctly and lawfully.  Debts and taxes are then paid and
                                        then the requirements as per the Will are put in place.</p>
                                <p>
                                    To get authority for probate to happen, a legal document called a Grant of Probate
                                    is needed. Where a valid Will has been made, Grant of Probate must be obtained, appointing
                                    the named Executors to administer the estate.
                                </p>


                                <a id="letter_administration_title"></a>
                                <br/>
                                <br/>
                                <p><b>Letters of Administration / Intestacy</b></p>

                                <p>If you pass away without making a Will, it is known as dying “intestate”. This
                                    process is more complicated than probate and is often costly for your loved ones.
                                    It may also mean your estate is not disposed of as you may have wanted it to be
                                    and sometimes it means your estate ends up going to the Government.</p>

                                    <p>Letters of Administration is an Order granted by the Supreme Court which gives a
                                        person the legal right to deal with the estate of a deceased person. It is often
                                        still necessary for someone to obtain the legal right to administer the deceased
                                        estate. This may include any property, bank accounts, superannuation funds and
                                        other assets that the deceased person has left behind. </p>

                                    <p>Your next of kin can apply for a Grant of Letters of Administration with a Will
                                        annexed if the executors are unable or unwilling to apply for the Grant of
                                        Probate. Letters of Administration give your next of kin legal powers to deal
                                        with your assets and administrate much in the same way as an executor would.
                                        Letters of Administration are the equivalent of a Grant of Probate.</p>

                                    <p>If someone does not have a Will and passes away or the Will in not valid, then
                                        Letters of Administration with no Will annexed can be applied for and is known as
                                        dying intestate. This means it is up to the Supreme Court to appoint an ‘Administrator’
                                        to undertake this task. As there is no Will to confer this authority, Letters of
                                        Administration gives the Administrator the right to deal with the intestate’s
                                        assets and to distribute them to any beneficiaries in accordance with the Wills
                                        Act.</p>

                                <br/>
                                 <p><b>When Do I Need Letters of Administration?</b></p>
                                <p>
                                     Letters of Administration must be applied for whenever someone dies intestate, leaving
                                     behind an estate that is valued over a certain amount or an estate that contains
                                     real property. If you are acting as the executor of a loved one’s Will, or they
                                     have died without leaving a Will, handling their estate can feel like a heavy
                                     burden and a lot more costly.</p>

                                <br/>
                               <p><b>Administration of Estate</b></p>
                                <p>
                                   We can help you deal with the estate of someone who has died. We offer advice on issues
                                   such as inheritance tax planning, trusts, probate and estate administration. Whatever
                                   your situation is, we have the expertise to ensure the wishes are carried out correctly
                                   and efficiently.</p>

                                  <p>Whether the Will was made with us, a homemade Will or another firm, we will take care
                                      of all legal formalities ensuring the process is as stress-free for you where
                                      possible. Dealing with a Will can be time-consuming and attention to detail is
                                      essential. An experienced Solicitor can take over much of this burden at a
                                      difficult time and provide practical advice. If we prepared the Will, we will
                                      already be aware of its provisions and are ready to act upon them. We can do as much
                                      or as little as you wish, to assist with the administration of the estate. This
                                      could include simply obtaining the grant of representation only based on the information
                                      you provide with you completing the rest of the administration, up to and including
                                      our full service of us dealing with the whole administration from start to finish
                                      on your behalf.</p>



                                    <p>We can offer a bespoke service such as dealing with one or more of the following:</p>
                                    <ul>
                                        <li>Acting as executors and trustees</li>
                                        <li>Identifying and valuing assets</li>
                                        <li>Applying for Grant of Probate or Letters of Administration (Uncontested)</li>
                                        <li>Full Administration of the Estate</li>
                                        <li>Inheritance Tax and Grant of Probate / Letters of Administration Only</li>
                                        <li>Identifying and paying debts</li>
                                        <li>Claiming benefits / exemptions from Inheritance Tax</li>
                                        <li>Preparing HM Revenue & Customs accounts</li>
                                        <li>Dealing with beneficiaries</li>
                                        <li>Deed of Variation and family arrangements</li>
                                        <li>Administration of continuing Trusts</li>
                                        </ul>

                                    <p>Dealing with a Will and / or estate can be time-consuming and attention to detail
                                        is essential. An experienced Solicitor can take over much of this burden in such
                                        a difficult time and provide practical advice efficiently. We can also obtain
                                        the necessary Grant of Probate or Letters of Administration, deal with tax
                                        affairs, distribution of the estate, advise on the preparation of estate
                                        accounts, tax mitigation and if required deeds of variation.</p>

                                    <p>Personal representatives are responsible for ensuring that all assets are
                                        identified and properly declared. To assist with this we can carryout a Landmark
                                        Financial Asset Search (FAS).</p>


                                <a id="deputyship_title"></a>
                                <br/>
                                <br/>
                                <p><b>Deputyship</b></p>

                                <p>
                                    Deputyship is a way of getting the legal authority to make decisions on someone’s
                                    behalf, if that person no longer has the ability to grant a <a href="/lpas"><b>Lasting Power of Attorney
                                        (LPA)</b></a> or <a href="/lpas"><b>Enduring Power of Attorney (EPA)</b></a>.
                                </p>

                                <p>
                                    In these cases, it might be necessary to appoint a Deputy, who will be responsible
                                    for making decisions on behalf of the individual when it comes to things like their
                                    finances, property and issues regarding health and welfare. It is usually a close
                                    friend or family member who applies to become a Deputy, but it can also be a
                                    professional. Deputyships require an application being made to the Court of
                                    Protection; each case is reviewed by a specialist judge. A court order makes the
                                    Deputyship legally binding and enables the necessary decisions to be made on behalf
                                    of the vulnerable person.
                                </p>

                                <br/>
                                <p><b>What is a deputyship?</b></p>

                                <p>A deputyship is where a person (known as a Deputy) is appointed to make decisions on
                                    behalf of someone who does not have the mental capacity to manage their own property
                                    and Financial Decisions or Health & Care Decisions. A court order is required for
                                    a Deputy to act.

                                    The Court of Protection deputyship needs to be applied for in the specific areas
                                    that decisions need to be made, as there are different types, depending on the
                                    circumstances. Deputyships are applied for when the individual has been assessed
                                    as not being capable of making specific types of decisions.

                                    Deputyship application process to become someone's deputy, you firstly need to meet the eligibility criteria.
                                    This means you:

                                    <ul>
                                        <li>Must be over the age of 18; and</li>
                                        <li>Must have the skills to make financial decisions for someone else if applying
                                            to be a property and Financial Decisions deputy</li>
                                    </ul>
                                </p>

                                    <p>A Deputy is usually, but not always, a relative or close friend of the person who
                                        lacks capacity. If it is appropriate, the Court of Protection may appoint a
                                        professional as Deputy, such as solicitors, accountants or local authority
                                        representatives.</p>

                                    <p>If you are eligible and willing to become an individual or joint-Deputy for
                                        someone who needs help with making decisions, you will need to apply to the
                                        Court of Protection, who will assess the application and then make a decision
                                        on the appointment.</p>

                                    <p>The deputyship application timescales can vary, depending on the circumstances,
                                        but the process usually takes approximately six months.</p>

                                    <br/>

                                <p><a id="court_protection_title"><b>Court of Protection</b></a></p>

                                <p><b>What is the Court of Protection?</b></p>

                                    <p>The Court of Protection is a superior Court under English law, created under the
                                        Mental Capacity Act 2005 to have jurisdiction over the property, Financial
                                        Decisions. and personal welfare of anybody who lacks the mental capacity to make
                                        informed decisions for themselves.</p>

                                    <p>There are a number of reasons you might need to apply to the Court of Protection such as:</p>

                                    <ul>
                                        <li>Asking the Court to make a decision about someone’s property and Financial
                                            Decisions or their Health & Care Decisions.</li>
                                        <li>Applying to be made a deputy for someone – a deputy is someone who is
                                            appointed by the Court of Protection to deal with the day to day running of
                                            the individuals finances and property.</li>
                                        <li>If you want to make a Will on someone else’s behalf.</li>
                                        <li>If you want to object to the registration of a Power of Attorney.</li>
                                    </ul>

                                    <p><b>How we can help with Probate, Letters of Administration, Court of Protection and / or Deputyship?</b></p>

                                    <p>We, at Dienn’s Law Solicitors can help with many aspects of becoming a Deputy,
                                        obtaining the Grant of Probate or Letters of Administration and Court of Protection.
                                        Whether that is assistance with the application process or  guidance when it comes
                                        to making decisions in the individual's best interests. We can offer the benefit
                                        of our experience when it comes to helping you manage the personal welfare needs
                                        of the individual:

                                <ul>
                                    <li>Asking the Court to make a decision about someone’s property and Financial
                                        Decisions or their Health & Care Decisions</li>
                                    <li>Applying to be made a deputy for someone – a deputy is someone who is
                                        appointed by the Court of Protection to deal with the day to day running of
                                        the individuals finances and property</li>
                                    <li>If you want to make a Will on someone else’s behalf</li>
                                    <li>If you want to object to the registration of a Power of Attorney.</li>
                                </ul>

                                    </p>
                                <p><b>How we can help with Probate, Letters of Administration, Court of Protection and / or Deputyship?</b></p>

                                <p>We, at Dienn’s Law Solicitors can help with every aspect of becoming a Deputy,
                                    obtaining the Grant of Probate or Letters of Administration and Court of Protection.
                                    Whether that is assistance with the application process or  guidance when it comes
                                    to making decisions in the individual's best interests. We can offer the benefit
                                    of our experience when it comes to helping you manage the personal welfare needs
                                    of the individual.</p>

                                <p>Making decisions on someone else's behalf can be very difficult and some people find
                                    it quite stressful especially at first. Our team of experienced and compassionate
                                    solicitors can provide the expert advice that you need, along with practical advice
                                    on things such as:</p>

                                <ul><li>Paying for care</li>
                                    <li>Accessing state benefits, if applicable</li>
                                    <li>Making an application for a statutory Will</li>
                                    <li>Making decisions about accommodation and healthcare</li>
                                    <li>Submitting deputyship reports (due annually) to the Office of the Public Guardian</li>
                                    <li> Obtaining the Grant of Probate or Letters of Administration</li>
                                    <li>Obtaining an order for Deputyship</li>
                                    <li>Obtaining an order for Court of Protection.</li>
                                </ul>
                                <br/>

                                <p>By having a valid up to date Will is very important. We can assist you with
                                    preparing an updated valid Will, as well as offering expert advice in the
                                    complex and rapidly changing area of estate and inheritance tax planning. It is
                                    becoming increasingly common for a Will to be challenged and a Probate Court
                                    may be called upon to decide the legal position. Therefore, having a
                                    professionally written and valid Will makes Probate more straightforward and
                                    easier for Executors and your love ones to defend challenges to a Will. Please
                                    click <a href="/wills-and-trusts"><b>here</b></a> for more details.</p>

                                <p>In addition, it is also important to have an up-to-date registered Enduring Power
                                    of Attorney or Lasting Power of Attorney for both Health & Care Decisions and Property
                                    and Financial Decisions
                                    giving authority to those you trust to act and make decisions on your behalf.
                                    Please click <a href="/lpas"><b>here</b></a> for more details.</p>

                                <p>If you would like more information or have a query please feel free to
                                    <a href="/contact-us"><b> contact us.</b></a> </p>

                                <br/>
                                    <p>
                                    Other Areas of Law:
                                    <ul>
                                        <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                        <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                        <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                        <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                        <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                        <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                        <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                        <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                        <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                    </ul>



                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Probate;
