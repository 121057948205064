/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class ProbatePricing extends React.Component {


    componentDidMount() {
         window.scrollTo(0, 0);
        document.body.classList.toggle("probate-pricing");
    }

    componentWillUnmount() {
        document.body.classList.toggle("probate-pricing");
        document.documentElement.removeEventListener(
            "mousemove",
            this.followCursor
        );
    }

    followCursor = event => {
        let posX = event.clientX - window.innerWidth / 2;
        let posY = event.clientY - window.innerWidth / 6;
        this.setState({
            squares1to6:
                "perspective(500px) rotateY(" +
                posX * 0.05 +
                "deg) rotateX(" +
                posY * -0.05 +
                "deg)",
            squares7and8:
                "perspective(500px) rotateY(" +
                posX * 0.02 +
                "deg) rotateX(" +
                posY * -0.02 +
                "deg)"
        });
    };

    render() {

        const priceChangeList = [
            {description: 'Valid will', key: 0},
            {description: 'More than one property', key: 1},
            {description: 'Assets based outside the UK', key: 2},
            {description: 'There are share holdings (stocks and bonds)', key: 3},
            {description: 'Sale or transfer of any property in the estate', key: 4},
            {description: 'More than three bank or building society accounts', key: 5},
            {description: 'Other intangible assets', key: 6},
            {description: 'More than two beneficiaries', key: 7},
            {description: 'Disputes between beneficiaries on division of assets or other disputes', key: 8},
            {description: 'Inheritance tax is payable and the executors need to submit a full account to HMRC', key: 9},
            {description: 'Assets/liabilities that only come to light after the probate application has been submitted, and/or', key: 10},
            {description: 'Claims made against the estate', key: 11},
            {description: 'Abortive transactions', key: 12}]

        return (
            <>

                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">

                                <h5 className="text-on-back">Grant of Probate / Letters of Administration (Uncontested)</h5>
                                <br/>
                                <p>At Dienn’s Law Solicitors we pride ourselves on being trusted professional advisers
                                    to individuals and businesses. We always aim to ensure people have accurate and
                                    relevant information about us and their solicitor when they are considering
                                    purchasing legal services.</p>

                                <br/>
                                <p>From 6 December 2018, we are required to publish information on our website relating
                                    to probate and intestacy.</p>
                                <p>Whilst we endeavour to provide information that is as accurate as possible, the
                                    pricing details shown on our website are examples and should only be used as a
                                    guide as pricing may vary depending on each individual circumstance. Every client
                                    will receive a client care letter and terms of business setting out our costs based
                                    on their needs and the specific facts of the service they require.</p>
                                <br/>
                                <p>We are here every step of the way to help you through this difficult time. We offer
                                    a variety of services depending on our clients or their loved ones requirements and
                                    wishes. It may be dealing with Inheritance Tax and obtaining a grant of probate or
                                    letters of administration only or even dealing with the whole administration of the
                                    estate on your behalf. We can tailor our services to meet your needs.
                                </p>
                                <br/>
                                <p>The hourly rate ranges from £225 - £300 plus VAT and disbursements, depending
                                    on the fee earner carrying out the work on your transaction. However, in
                                    many cases we offer a fixed percentage of the estate value as our fee. Although,
                                    depending on the number of hours spent on your file, the number of beneficiaries,
                                    assets, accounts, whether there is any estate abroad and so forth, we reserve the
                                    right to charge an hourly rate for estates which do not have the following: </p>
                                    <ol>
                                        {priceChangeList.map(transaction => {
                                            return (
                                                <li key={transaction.key}>{transaction.description}</li>
                                            );
                                        })}
                                    </ol>

                                <p> Please note that the above list is not exhaustive and is an example whereby fees may need to be increased.</p>
                            <br/>
                                <p><b>Full Administration of the Estate</b></p>
                                <p>As part of our service we will:</p>
                                <ul>
                                    <li><p>Provide you with a dedicated probate solicitor to work on your matter</p></li>
                                    <li><p>Identify the legally appointed executors or administrators and beneficiaries</p></li>
                                    <li><p>Identify the type of probate application that you will require</p></li>
                                    <li><p>Obtain the relevant documents required to make the Probate Application which
                                           includes valuations and statements on all assets</p></li>
                                    <li><p>Complete the Probate Application and the relevant HMRC forms</p></li>
                                    <li><p>Draft a legal oath for the executors behalf</p></li>
                                    <li><p>Obtain Probate</p></li>
                                    <li><p>Collect and distribute all assets in the estate (after paying off any debts)
                                           such as legacies, dealing with income and capital gains taxes where applicable,
                                           reviewing the closing bank statement, preparing estate accounts, general
                                        correspondence with the executors and beneficiaries.</p></li>
                                </ul>

                                <p>If Dienn’s Law Solicitors are not executors then it is charged at 1.25% plus VAT of
                                    the gross estate (including agreeable assets, such as trusts) excluding the
                                    deceased’s property, and 0.75% plus VAT of the value of the residence property
                                    plus VAT. Where Dienn’s Law Solicitors are executors then it is increased to 1.75%
                                    plus VAT and 1% plus VAT respectively.
                                </p>
                                <p>Our minimum chargeable fee (not including hourly rate on work due) is £1,500 plus
                                    VAT and disbursements.</p>
                                <p>We can refer any conveyancing transactions to our specialist conveyancing
                                    department who will then provide a separate fee estimate for such work.</p>
                                <p>We can refer any litigation transactions to our litigation consultant firm who will
                                    also provide a separate fee estimate for such work.
                                </p>
                                <p>The fees charged are subject to the “fair and reasonable” rule which means that if
                                circumstances dictate, then our fees will be reduced to reflect the transaction. For
                                example, if the deceased has £20 million in only one bank account and no other estate,
                                then it may not be fair and reasonable to charge the full percentages detailed above
                                    and our fees would be reduced and agreed with you accordingly.</p>
                                <p>Please do <a href="/contact-us"> contact us</a> for more information and so that we
                                    can discuss your transaction or queries in more detail.</p>
                            <br/>
                                <p><b>Inheritance Tax and Grant of Probate Only</b></p>
                                <p>We will prepare the Inheritance Tax account from information provided by the executor
                                    and we will prepare the probate application and obtain the grant of probate or
                                    letters of administration. The executor is personally responsible for all other
                                    matters such ascertaining the assets and liabilities, collecting in the assets,
                                    dealing with any income and capital gains tax and paying the liabilities and
                                    beneficiaries.</p>

                                <p>We charge a fixed fee, the amount of which will be discussed at the outset and will
                                    depend on which Inheritance Tax account(s) are required, whether inheritance tax is
                                    payable and whether there are any other complexities in the accounts such as failed
                                    Potentially Exempt Transfers (PET’s) and Chargeable Lifetime Transfers (CLT’s) known
                                    as lifetime gifts or trust aggregation. Our fee range for the majority of
                                    transactions are estimated between £1,000-£1,750 plus VAT and disbursements.
                                </p>
                            <br/>
                                <p><b>Bespoke Service</b></p>
                                <p>We will customise our service to the executor’s requirements which may be somewhere
                                    in between the Full Administration of the Estates and the Inheritance Tax and Grant
                                    of Probate above mentioned services. For instance, this could be where executors
                                    require advice and general assistance and not for us to obtain the format grant of
                                    probate or letters of administration.
                                </p>

                                <p>This will either be charged on an hourly basis or by a fixed fee which would be
                                    agreed from the outset of the transaction.</p>

                                <br/>
                                <p><b>Likely Disbursements</b></p>

                                <ul>
                                    <li><p>Bank transfer fee of £42 inclusive of VAT per transfer.</p></li>
                                    <li><p>Probate Court Fees: £273 plus £1.50 per office copy of the grant of probate or letters of administration.</p></li>
                                    <li><p>Bankruptcy Searches: £7.20 inclusive of VAT per person for 2 searches at £3.60 inclusive of VAT for each search.</p></li>
                                    <li><p>Office Copy entries from Land Registry: £4.80 inclusive of VAT per document.</p></li>
                                    <li><p>Estatesearch missing asset search: £234 inclusive of VAT (if required).</p></li>
                                    <li><p>Statutory advertisements, usually in the region of £240 inclusive of VAT (if required).</p></li>
                                    <li><p>Valuation Fees, these vary depending on assets (if required).</p></li>
                                    <li><p>Swearing of the Oath (per executor): £7.</p></li>
                                    <li><p>Will Search with the National Register: £150 inclusive of VAT.</p></li>
                                    <li><p>Anti-Money Laundering Check: 7.80 inclusive of VAT.</p></li>
                                    <li><p>Electronic Verification of ID: £11 inclusive of VAT per person.</p></li>
                                    <li><p>Gazette Notices – S.27: £110.40 inclusive of VAT (if required).</p></li>
                                </ul>
                                <br/>
                                <p><b>Timescales</b></p>

                                <p>It is difficult  to provide a fixed timeframe for completion of your transaction as
                                    we are dependent on third parties providing us with information by external source
                                    as banks, tax offices, the probate registry and other financial institutions.</p>
                                <p>For a very straightforward, non-taxable grant only transaction and other simple
                                    estates are likely to be between 6-9 months.</p>
                                <p>For complex Full Administration of the Estates transaction are likely to be between
                                    12-18 months given assets need to be collected, deal with liabilities and sell any
                                    property and finalise tax matters. Sometimes it may be quicker than this if the
                                    estate is small and straightforward, but it may take longer if there are
                                    difficulties in selling the property or other complexities such as further dealings
                                    with HMRC which may become necessary as the administration progresses (e.g. if the
                                    Property sells for significantly more than the probate value and we need to deal
                                    with Capital Gains Tax) or any claims against the estate which require the
                                    intervention of a litigator.</p>
                                <p>We will be able to give you a more accurate timeframe when the details of the estate
                                    are known, and we will provide you with further timeframe updates as the matter
                                    progresses.</p>
                                <br/>
                                <p><b>Whom will carry out your work:</b></p>
                                <p>Work will be carried out or supervised by an experienced solicitor.</p>
                                <br/>

                                <p>If you have any questions about our service and pricing, please call a member of the
                                    team where we will be happy to talk you through the options on
                                    <a href="tel:01403 379919"> 01403 379919</a> or
                                    email us on  <a href="mailto:info@diennslaw.co.uk"> info@diennslaw.co.uk.</a>
                                </p>

                                <br/>
                                <p>
                                    Other Areas of Law:
                                    <ul>
                                        <a href="/agricultural"><li><u><b>Agricultural & Rural Property</b></u></li></a>
                                        <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                        <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                        <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                        <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                        <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                        <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                        <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                        <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                        <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                    </ul>
                                </p>

                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default ProbatePricing;
